/** @jsx jsx */
import { jsx, Box, Styled } from 'theme-ui';
import Reac, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { navigate } from 'gatsby';
import { ParcelList } from '../../account/ParcelList';
import { SendParcelWidget, TrackingSearchWidget } from '../../components/widgets';
import AccountLayout from '../../account/AccountLayout';
import ShipmentDetails from '../../account/ShipmentDetails';
import FullHeightColumn from '../../components/FullHeightColumn';
import * as analytics from '../../utils/analytics';
import { isBrowser } from '../../utils';

const TabButton = ({ label, select, isSelected }) => {
  return (
    <div
      sx={{
        py: 1,
        px: 3,
        borderRadius: 1,
        bg: isSelected ? 'primary' : 'grayLighter',
        color: isSelected ? 'white' : 'secondary',
        cursor: 'pointer',
        fontWeight: isSelected ? 'bold' : 'normal',
      }}
      role="tab"
      aria-selected={isSelected}
      onClick={select}
    >
      {label}
    </div>
  );
};

const TabList = ({ items, value, select }) => {
  return (
    <div
      sx={{
        display: 'inline-flex',
        flexDirection: 'row',
        p: 1,
        bg: 'grayLighter',
        borderRadius: 1,
        my: 3,
      }}
      role="tablist"
    >
      {items.map((it, index) => (
        <TabButton key={index} {...it} isSelected={value === it.value} select={() => select(it.value)} />
      ))}
    </div>
  );
};

export default ({ location, pageContext }) => {
  analytics.usePageCategory('paketit');
  const translate = getTranslate(useSelector(state => state.localize));
  const title = translate('account.navigation.archive');
  const { shipment: selectedShipment = null } = location.state || {};
  const showDetails = !!selectedShipment;
  const [mode, setMode] = useState('received');
  const selectShipment = useCallback(sh => {
    navigate('.', { state: { shipment: sh } });
  }, []);

  const items = [
    { value: 'received', label: translate('account.parcels.received') },
    { value: 'sent', label: translate('account.parcels.sent') },
  ];

  if (!isBrowser) {
    // fix rehydration bug
    return (
      <AccountLayout
        title={title}
        paths={pageContext.paths}
        locale={pageContext.locale || 'en'}
        sidebar={
          <Box>
            <SendParcelWidget sidebar sx={{ mb: 3 }} />
            <TrackingSearchWidget sidebar />
          </Box>
        }
      >
        <FullHeightColumn />
      </AccountLayout>
    );
  }

  return (
    <AccountLayout
      title={title}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
      sidebar={
        <Box>
          <SendParcelWidget sidebar sx={{ mb: 3 }} />
          <TrackingSearchWidget sidebar />
        </Box>
      }
    >
      <FullHeightColumn>
        <Styled.h1 sx={{ m: 0 }}>{translate('account.navigation.archive')}</Styled.h1>
        <div
          sx={{
            display: !showDetails ? 'block' : 'none',
          }}
        >
          <TabList items={items} value={mode} select={setMode} />
          <ParcelList type={mode} selectShipment={selectShipment} showArchived={true} />
        </div>
        <div sx={{ display: showDetails ? 'block' : 'none' }}>
          {showDetails && <ShipmentDetails shipment={selectedShipment} goBack={() => selectShipment(null)} />}
        </div>
      </FullHeightColumn>
    </AccountLayout>
  );
};
